import { combineReducers } from "redux";
import alert from "../alert";
import web3 from "../web3";
import ContactReducer from "../../../../pages/directory/redux/reducers/contact_reducer";
import IncomeReducer from "../../../../pages/treasury/redux/reducers/income_reducer";
import OutcomeReducer from "../../../../pages/treasury/redux/reducers/outcome_reducer";
import IncomeTypeReducer from "../../../../pages/treasury/redux/reducers/income_type_reducer";
import TagReducer from "../tag_reducer";
import SubjectReducer from "../subject_reducer";
import StatusReducer from "../status_reducer";
import CategoryReducer from "../category_reducer";
import TargetReducer from "../target_reducer";
import OutcomeTypeReducer from "../../../../pages/treasury/redux/reducers/outcome_type_reducer";
import { RootState } from "../../../../store";
import CaseReducer from "../../../../pages/cases/redux/reducers/case_reducer";
import UserAccountReducer from "../../../../pages/auth/redux/reducers/user_account_reducer";
import GoogleReducer from "../google_reducer";
import InstitutionReducer from "../institution_reducer";
import SecondStatusReducer from "../second_status_reducer";
import GroupReducer from "../group_reducer";
import UserAccountOfficeGroupReducer from "../../../../pages/auth/redux/reducers/user_account_office_group_reducer";
import TestReducer from "../test_reducer";
import OfficeObjectReducer from "../../../../pages/tracking/redux/reducers/office_object_reducer";
import SeatReducer from "../../../../pages/tracking/redux/reducers/seat_reducer";
import TreasuryReducer from "../../../../pages/treasury/redux/reducers/treasury_reducer";
import TrashReducer from "../../../../pages/documents/redux/reducers/trash_reducer";

export default combineReducers<RootState>({
  alert,
  web3,
  google_state: GoogleReducer,
  case_state: CaseReducer,
  contact_state: ContactReducer,
  income_state: IncomeReducer,
  outcome_state: OutcomeReducer,
  income_type_state: IncomeTypeReducer,
  outcome_type_state: OutcomeTypeReducer,
  tag_state: TagReducer,
  subject_state: SubjectReducer,
  status_state: StatusReducer,
  category_state: CategoryReducer,
  target_state: TargetReducer,
  user_account_state: UserAccountReducer,
  institution_state: InstitutionReducer,
  second_status_state: SecondStatusReducer,
  group_state: GroupReducer,
  user_account_office_group_state: UserAccountOfficeGroupReducer,

  test_state: TestReducer,
  office_object_state: OfficeObjectReducer,
  seat_state: SeatReducer,
  treasury_state: TreasuryReducer,
  trash_state: TrashReducer,
});
