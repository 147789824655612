import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./core/redux/reducers/combinations/root_index";
import { composeWithDevTools } from "redux-devtools-extension";
import { ContactState } from "./pages/directory/redux/types/contact_types";
import { CoreState } from "./core/redux/types/core_types";
import { IncomeState } from "./pages/treasury/redux/types/income_types";
import { CaseState } from "./pages/cases/redux/types/case_types";
import { OutcomeState } from "./pages/treasury/redux/types/outcome_types";
import { TagState } from "./core/redux/types/tag_types";
import { SubjectState } from "./core/redux/types/subject_types";
import { StatusState } from "./core/redux/types/status_types";
import { CategoryState } from "./core/redux/types/category_types";
import { TargetState } from "./core/redux/types/target_types";
import { IncomeTypeState } from "./pages/treasury/redux/types/income_type_types";
import { OutcomeTypeState } from "./pages/treasury/redux/types/outcome_type_types";
import { UserAccountState } from "./pages/auth/redux/types/user_account_types";
import { GoogleState } from "./core/redux/types/google_types";
import { SecondStatusState } from "./core/redux/types/second_status_types";
import { GroupState } from "./core/redux/types/group_types";
import { InstitutionState } from "./core/redux/types/institution_types";
import { FolderState } from "./pages/documents/redux/types/folder_types";
import { DocumentState } from "./pages/documents/redux/types/document_types";
import { UserAccountOfficeGroupState } from "./pages/auth/redux/types/user_account_office_group_types";
import { GeneralDataState } from "./core/redux/types/general_data_types";
import { TestState } from "./core/redux/types/test_types";
import { OfficeObjectState } from "./pages/tracking/redux/types/office_object_types";
import { Seat, SeatState } from "./pages/tracking/redux/types/seat_types";
import {
  Treasury,
  TreasuryState,
} from "./pages/treasury/redux/types/treasury_types";
import { TrashState } from "./pages/documents/redux/types/trash_types";

export interface RootState {
  // [ANCHOR_1]
  network?: number;
  google_state?: GoogleState;
  core_state?: CoreState;
  alert?: any;
  web3?: any;

  case_state?: CaseState;

  contact_state?: ContactState;
  income_state?: IncomeState;
  outcome_state?: OutcomeState;
  income_type_state?: IncomeTypeState;
  outcome_type_state?: OutcomeTypeState;
  tag_state?: TagState;
  subject_state?: SubjectState;
  status_state?: StatusState;
  category_state?: CategoryState;
  target_state?: TargetState;
  second_status_state?: SecondStatusState;
  group_state?: GroupState;
  folder_state?: FolderState;
  document_state?: DocumentState;

  institution_state?: InstitutionState;
  user_account_state?: UserAccountState;
  user_account_office_group_state?: UserAccountOfficeGroupState;
  general_data_state?: GeneralDataState;

  test_state?: TestState;
  office_object_state?: OfficeObjectState;
  seat_state?: SeatState;
  treasury_state?: TreasuryState;
  trash_state?: TrashState;
}

const initialState: RootState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
